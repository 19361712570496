import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import { Button, Flex, Image, Text } from "@mantine/core";
import { useAtom } from "jotai";

import { ErrorAlert, Loader, UserDetails } from "components";
import ServiceInfoCard from "views/TicketServices/ServiceInfoCard";
import ServiceBuyingSummary from "views/TicketServices/ServiceBuyingSummary";
import { ChevronsIconLeft } from "assets/icon";

import { serviceStep } from "store/atoms";
import {
  BOOK_SERVICE_BODY,
  BOOKING_ID,
  STRIPE_AC_ID,
  IDEAL_BANK_NAMES,
} from "constants";
import { useCreateStripePaymentIntentMutation } from "store/services/ticketServices";

import iDEALIcon from "assets/icon/iDEAL.png";

export default function IdealPayment() {
  const location = useLocation();
  const navigate = useNavigate();
  const { idealBank } = location.state;
  const reduxstate = useSelector((state) => state.State);
  const stripeAcId = window.localStorage.getItem(STRIPE_AC_ID);
  let bookServiceBody = window.localStorage.getItem(BOOK_SERVICE_BODY) || {};
  bookServiceBody = Object.keys(bookServiceBody).length
    ? JSON.parse(bookServiceBody)
    : {};

  console.log("Bookservicebody", bookServiceBody);
  console.log("Redux", reduxstate);
  console.log("StripeId", stripeAcId);

  const [stripe, setStripe] = useState(null);
  const [, setServiceStep] = useAtom(serviceStep);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [createPaymentIntent] = useCreateStripePaymentIntentMutation();

  async function getClientSecret() {
    const body = {
      serviceId: bookServiceBody.service_id,
      slotDuration: bookServiceBody.duration,
      priceType: bookServiceBody.priceType,
      slotPrice: Number(reduxstate.slotData?.price),
      slotDescription:
        reduxstate.slotData?.description || reduxstate.serviceData.serviceName,
    };

    const serviceBookingPayload = {
      ...bookServiceBody,
      bookingId: "",
      utcOffset: new Date().getTimezoneOffset(),
    };

    const response = await createPaymentIntent({
      amount: Number(reduxstate.totalPrice),
      paymentMethod: "ideal",
      currency: "eur",
      isPackage: false,
      serviceBookingPayload,
      ...body,
    }).unwrap();

    return response?.data;
  }

  useEffect(() => {
    loadStripe(
      process.env.REACT_APP_ENVIRONMENT === "PROD"
        ? process.env.REACT_APP_STRIPE_LIVE_KEY
        : process.env.REACT_APP_STRIPE_TEST_KEY,
      stripeAcId ? { stripeAccount: stripeAcId } : undefined
    ).then(async (stripeRes) => {
      setStripe(stripeRes);
    });
  }, [stripeAcId]);

  const handlePay = async () => {
    setIsSubmitting(true);

    const { client_secret, referenceId } = await getClientSecret();

    if (!client_secret) {
      setIsSubmitting(false);

      confirmAlert({
        customUI: ({ onClose }) =>
          ErrorAlert({
            onClose: () => {
              onClose();
              handleChangePaymentMethod();
            },
            error: "Please try again later",
            isServerError: true,
          }),
        closeOnClickOutside: false,
        closeOnEscape: false,
      });
      return;
    }

    window.localStorage.setItem(BOOKING_ID, referenceId);

    let return_url = process.env.REACT_APP_STRIPE_RETURN_URL_DEV;

    switch (true) {
      case process.env.REACT_APP_ENVIRONMENT === "PRE_STAGING":
        return_url = process.env.REACT_APP_STRIPE_RETURN_URL_PRE_STAGING;
        break;
      case process.env.REACT_APP_ENVIRONMENT === "STAGING":
        return_url = process.env.REACT_APP_STRIPE_RETURN_URL_STAGING;
        break;
      case process.env.REACT_APP_ENVIRONMENT === "PROD":
        return_url = process.env.REACT_APP_STRIPE_RETURN_URL_PROD;
        break;
      default:
        break;
    }

    const { error, paymentIntent } = await stripe.confirmIdealPayment(
      client_secret,
      {
        payment_method: {
          ideal: {
            bank: idealBank,
          },
        },
        return_url: `${return_url}/payment-check`,
      }
    );

    if (paymentIntent) {
      if (paymentIntent.status === "succeeded") {
        if (reduxstate.currentDataFor === "ticket") {
          navigate("/payment-complete", {
            state: { paymentSuccess: true },
          });
        } else if (reduxstate.currentDataFor === "service") {
          navigate("/service-payment-complete", {
            state: { paymentSuccess: true },
            replace: true,
          });
        }
      }
    }

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.

    if (
      error &&
      (error.type === "card_error" || error.type === "validation_error")
    ) {
      setIsSubmitting(false);

      confirmAlert({
        customUI: ({ onClose }) =>
          ErrorAlert({
            onClose: () => {
              onClose();
              handleChangePaymentMethod();
            },
            error: error.message,
            isServerError: false,
          }),
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    } else {
      setIsSubmitting(false);

      confirmAlert({
        customUI: ({ onClose }) =>
          ErrorAlert({
            onClose: () => {
              onClose();
              handleChangePaymentMethod();
            },
            error: "An unexpected error occurred.",
            isServerError: false,
          }),
        closeOnEscape: false,
        closeOnClickOutside: false,
      });
    }
  };

  const handleChangePaymentMethod = () => {
    setServiceStep(4);
    navigate(-1, { replace: true });
  };

  if (!stripe) {
    return (
      <div className="ticket-view-loading">
        <Loader />
      </div>
    );
  }

  return (
    <div className="ticket-services">
      <div className="wrapper">
        <div className="page-hdr">
          <button className="back-btn" onClick={handleChangePaymentMethod}>
            <ChevronsIconLeft />
            <div className="lbl">Back</div>
          </button>
          <div className="title">Review & Pay</div>
        </div>
        <div className="content">
          <Flex direction="column">
            <ServiceInfoCard
              serviceData={reduxstate.serviceData}
              resource={reduxstate.resourceDetail}
              isReadMore={false}
              selectedStep={5}
              toggleReadMore={() => {}}
              when={{
                day: bookServiceBody.slot_date,
                start: bookServiceBody.slot_start_time,
                end: bookServiceBody.slot_end_time,
              }}
              where={reduxstate.serviceSelectedLocation}
              slotData={reduxstate.slotData}
            />
            <UserDetails />
            <ServiceBuyingSummary reduxstate={reduxstate} />
            <div className="section">
              <div className="block">
                <Flex direction="row" justify="space-between" align="center">
                  <Flex direction="row" gap="sm" align="center">
                    <Image src={iDEALIcon} width={36} height={36} />
                    <Flex direction="column">
                      <Text fw={700} fz="lg">
                        iDEAL
                      </Text>
                      <Text fz="xs" opacity={0.8}>
                        {IDEAL_BANK_NAMES[idealBank]}
                      </Text>
                    </Flex>
                  </Flex>
                  <Button
                    variant="subtle"
                    onClick={handleChangePaymentMethod}
                    fz="md"
                    c="#584CF4"
                  >
                    Change
                  </Button>
                </Flex>
              </div>
            </div>
            <Button
              fullWidth
              radius={10}
              bg="#f64e60"
              size="lg"
              fz="md"
              onClick={handlePay}
              sx={{
                "&:hover": {
                  backgroundColor: "#f64e60ee",
                  transition: "background-color 0.2s ease-in-out",
                },
              }}
              loading={isSubmitting}
            >
              Pay & Confirm booking
            </Button>
          </Flex>
        </div>
      </div>
    </div>
  );
}
