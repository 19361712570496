import React, { useMemo } from "react";
import moment from "moment";
import { rem, Flex, Image, Text } from "@mantine/core";
import { useSelector } from "react-redux";

import { LocationIcon, TimerIcon } from "assets/icon";
import useIsMobile from "hooks/useIsMobile";

function ServiceInfoCard({
  businessDetail,
  serviceData,
  slotData,
  resource,
  isReadMore,
  toggleReadMore,
  selectedStep,
  when,
  where,
}) {
  const { isSmallMobile } = useIsMobile();

  const reduxstate = useSelector((state) => state.State);

  const resourceDetail = useMemo(() => {
    let selectedResource = { ...resource };

    if (
      Object.keys(selectedResource).length &&
      selectedResource.fullName !== ""
    ) {
      delete selectedResource.available_hours;

      return selectedResource;
    } else {
      selectedResource = reduxstate.resourceDetail;

      return selectedResource;
    }
  }, [reduxstate.resourceDetail, resource]);

  const Description = useMemo(() => {
    const content = serviceData?.description;

    if (content?.length < 60) {
      return () => (
        <div className="about-blk">
          <Text fz="lg" fw={600}>
            Service Details
          </Text>
          <div className="description">{content}</div>
        </div>
      );
    }

    return () => (
      <div className="about-blk">
        <Text fz="lg" fw={600}>
          Service Details
        </Text>
        <div className="description">
          {isReadMore ? `${content.substring(0, 140)}...` : content}
          {""}
          <span onClick={toggleReadMore} className="read-or-hide">
            {isReadMore ? " Read more" : " Show less"}
          </span>
        </div>
      </div>
    );
  }, [isReadMore, serviceData?.description, toggleReadMore]);

  return (
    <div className="section">
      <div className="block info">
        <Flex direction="row" gap="md">
          <Image
            src={serviceData?.servicePhoto}
            height={rem(70)}
            width={rem(110)}
            radius="md"
          />
          <Flex direction="column" gap={rem(4)}>
            <Text size={isSmallMobile ? "15px" : "16px"} weight={600}>
              {serviceData?.serviceName}
            </Text>
            <Flex direction="column">
              <Text fz="sm" color="#880088">
                {slotData?.description}
              </Text>
              <Flex direction="row" gap={rem(6)}>
                <Text fz="sm" color="dark.3">
                  {slotData?.duration} mins
                </Text>
                <Text fz="sm" color="dark.3">
                  -
                </Text>
                <Text fz="sm" color="dark.3">
                  €{slotData?.price}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        {selectedStep > 1 && (
          <Flex mt="xl" direction="column">
            <div className="item">
              <Image
                src={
                  resourceDetail?.profilePic &&
                  resourceDetail?.profilePic?.length > 0
                    ? resourceDetail?.profilePic
                    : `https://avatar.iran.liara.run/username?username=${resourceDetail?.fullName
                        ?.split(" ")
                        ?.join("+")}`
                }
                height={rem(40)}
                width={rem(40)}
                radius={rem(12)}
                mr={rem(12)}
              />
              <div className="meta">
                <div className="lbl">Host</div>
                <div className="value">{resourceDetail?.fullName}</div>
              </div>
            </div>
            <div className="item">
              <div className="icon">
                <LocationIcon />
              </div>
              <div className="meta">
                <div className="lbl">Where</div>
                <div className="value">{where?.venueName}</div>
                <div className="text">{where?.address?.address}</div>
              </div>
            </div>
            <div className="item">
              <div className="icon">
                <TimerIcon />
              </div>
              <div className="meta">
                <div className="lbl">When</div>
                <div className="value">
                  {moment(when?.day).format("dddd, MMMM D")} at {when?.start} -{" "}
                  {when?.end}
                </div>
              </div>
            </div>
          </Flex>
        )}
        {selectedStep <= 1 && <Description />}
      </div>
    </div>
  );
}

export default ServiceInfoCard;
