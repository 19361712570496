import React, { Suspense, lazy } from "react";
import { BrowserRouter, Route } from "react-router-dom";

import { SentryRoutes } from "instrument";

import BookingConfirmation from "views/BookingConfirmation";
import OrderConfirmation from "views/OrderConfirmation";
import StripePayment from "views/StripePayment";
import StripeCardPayment from "views/StripePayment/CardPayment";
import StripeIdealPayment from "views/StripePayment/IdealPayment";
import StripeSocialPayment from "views/StripePayment/SocialPayment";
import TikkiePayment from "views/TikkiePayment";
import TikkiePaymentVerification from "views/TikkiePaymentVerification";
import TikkieServicePaymentVerification from "views/TikkiePaymentVerification/ServicePayment";
import { GlobalFooter, Loader } from "components";

const SuspenseFallback = () => (
  <div className="ticket-view-loading">
    <Loader />
  </div>
);

const withSuspenseFallback = (WrappedComponent) => {
  return (props) => (
    <Suspense fallback={<SuspenseFallback />}>
      <WrappedComponent {...props} />
    </Suspense>
  );
};

// Pages
const TicketLoading = lazy(() => import("views/TicketLoading"));
const TicketLoadingSuspended = withSuspenseFallback(TicketLoading);
const TicketBooking = lazy(() => import("views/TicketBooking"));
const TicketBookingSuspended = withSuspenseFallback(TicketBooking);
const StripePaymentCheck = lazy(() =>
  import("views/StripePayment/CheckPayment")
);
const StripePaymentCheckSuspended = withSuspenseFallback(StripePaymentCheck);
const StripePaymentComplete = lazy(() =>
  import("views/StripePayment/PaymentComplete")
);
const StripePaymentCompleteSuspended = withSuspenseFallback(
  StripePaymentComplete
);
const TicketServices = lazy(() => import("views/TicketServices"));
const TicketServicesSuspended = withSuspenseFallback(TicketServices);
const StripeServicePaymentComplete = lazy(() =>
  import("views/StripePayment/PaymentCompleteService")
);
const StripeServicePaymentCompleteSuspended = withSuspenseFallback(
  StripeServicePaymentComplete
);
const AddToMyCalender = lazy(() => import("views/AddToMyCalender"));
const AddToMyCalenderSuspended = withSuspenseFallback(AddToMyCalender);
const ManageBooking = lazy(() => import("views/ManageBooking"));
const ManageBookingSuspended = withSuspenseFallback(ManageBooking);

const Routing = () => {
  return (
    <BrowserRouter>
      <SentryRoutes>
        <Route path="/:id" element={<TicketLoadingSuspended />} />
        <Route path="/booking/:name" element={<TicketBookingSuspended />} />
        <Route
          path="/BookingConfirmation/:id"
          element={<BookingConfirmation />}
        />
        {/* <Route path="order-confirmation/:id" element={<OrderConfirmation />} /> */}
        <Route path="/payment" element={<StripePayment />} />
        <Route
          path="/payment-complete"
          element={<StripePaymentCompleteSuspended />}
        />
        <Route
          path="/service-payment-complete"
          element={<StripeServicePaymentCompleteSuspended />}
        />
        <Route
          path="/payment-check"
          element={<StripePaymentCheckSuspended />}
        />
        <Route
          path="/tikkie-payment"
          element={<TikkiePayment />}
        />
        <Route
          path="/tikkie-pay-check/:id"
          element={<TikkiePaymentVerification />}
        />
        <Route path="/service/:name" element={<TicketServicesSuspended />} />
        <Route
          path="/tikkie-pay-service-check/:id"
          element={<TikkieServicePaymentVerification />}
        />
        <Route path="/card-payment" element={<StripeCardPayment />} />
        <Route path="/ideal-payment" element={<StripeIdealPayment />} />
        <Route path="/social-payment" element={<StripeSocialPayment />} />
        <Route
          path="/add-to-my-calender"
          element={<AddToMyCalenderSuspended />}
        />
        <Route
          path="/managebooking/service/:id"
          element={<ManageBookingSuspended />}
        />
        <Route
          path="/managebooking/event/:id"
          element={<ManageBookingSuspended />}
        />
      </SentryRoutes>
      <GlobalFooter />
    </BrowserRouter>
  );
};

export default Routing;
