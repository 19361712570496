import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { Flex, Text } from "@mantine/core";

import { Loader } from "components";
import BookingServiceConfirmation from "views/TicketServices/BookingServiceConfirmation";

import { sendServiceConfirmationEmail } from "../../core/methods";
import {
  ALL_SERVICE_SELECTED_IDX,
  BOOKING_ID,
  BOOK_SERVICE_BODY,
  REDUX_STATE,
} from "../../constants";
import { serviceStep } from "../../store/atoms";
import { updatestate } from "store/reducer";
import {
  useAddServiceBookingMutation,
  useCheckTikkiePayStatusMutation,
} from "store/services/ticketServices";

function ServicePayment() {
  const ticksID = window.localStorage.getItem("ticksID") || "";

  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let reduxstate = window.localStorage.getItem(REDUX_STATE);
  reduxstate = reduxstate ? JSON.parse(reduxstate) : {};

  const [, setSelectedStep] = useAtom(serviceStep);
  const [isLoading, setIsLoading] = useState(true);
  const [bookedServiceData, setBookedServiceData] = useState({});
  const [isBookingComplete, setIsBookingComplete] = useState(false);
  const utcOffset = new Date().getTimezoneOffset();
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  const [addServiceBooking, { isLoading: isAddServiceBookingLoading }] =
    useAddServiceBookingMutation();
  const [checkTikkiePayStatus, { isLoading: isCheckingTikkiePayStatus }] =
    useCheckTikkiePayStatusMutation();

  const handleSendEmailConfirmation = async (addServiceBookingResponse) => {
    const body = {
      addServiceBookingResponse,
      reduxstate,
      notificationType: "confirmation",
    };
    console.log("AddServiceBooking response", addServiceBookingResponse);
    const [isSuccess] = await sendServiceConfirmationEmail(body);

    if (!isSuccess) {
      throw new Error();
    }

    const newReduxStateForStorage = {
      ...reduxstate,
      totalPrice: 0,
    };
    window.localStorage.setItem(
      REDUX_STATE,
      JSON.stringify(newReduxStateForStorage)
    );
    return;
  };

  const fetchPaymentStatus = async () => {
    try {
      const { data, statusCode } = await checkTikkiePayStatus({
        paymentRequestToken: id,
      }).unwrap();

      console.log("Payment Status API Response:", data);

      if (statusCode === 200) {
        if (data?.paymentStatus === "Paid") {
          setIsLoading(false);
          const bookingPayload = data || {};

          console.log("Payment successful. Booking Payload:", bookingPayload);

          // Handle email confirmation
          await handleSendEmailConfirmation(bookingPayload);

          // Clear localStorage and update Redux state
          dispatch(updatestate({ serviceBookingReservationId: null }));
          window.localStorage.removeItem(BOOK_SERVICE_BODY);
          window.localStorage.removeItem(BOOKING_ID);
          window.localStorage.removeItem(ALL_SERVICE_SELECTED_IDX);

          setBookedServiceData(bookingPayload);
          setPaymentSuccess(true);
          setIsBookingComplete(true);
        } else {
          // Retry if payment is incomplete, with a delay
          setTimeout(fetchPaymentStatus, 3000);
        }
      } else {
        setIsLoading(false); // Stop loading if statusCode is not 200
      }
    } catch (error) {
      console.error("Error fetching payment status:", error);
      setIsLoading(false);
    }
  };
  console.log("payment success", paymentSuccess);
  console.log("booking CComplete", isBookingComplete);

  useEffect(() => {
    if (id) {
      (async () => {
        await fetchPaymentStatus();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onBack = () => {
    setSelectedStep(0);
    navigate(`/${ticksID}`, { replace: true });
  };

  useEffect(() => {
    window.onpopstate = () => {
      onBack();
      return;
    };
  }, []);

  if (isLoading) {
    return (
      <div className="ticket-view-loading">
        <Loader size={40} />
        <p>checking payment</p>
      </div>
    );
  }

  if (
    paymentSuccess &&
    isBookingComplete &&
    Object.keys(bookedServiceData).length > 0
  ) {
    return (
      <div className="ticket-services">
        <div className="wrapper">
          <BookingServiceConfirmation
            serviceData={reduxstate.serviceData}
            bookingConfirmationData={bookedServiceData}
            where={reduxstate?.serviceData?.venue_detail}
          />
        </div>
      </div>
    );
  }

  // if (Object.keys(bookedServiceData).length > 0) {
  //   return (
  //     <div className="ticket-services">
  //       <div className="wrapper">
  //         <BookingServiceConfirmation
  //           serviceData={reduxstate.serviceData}
  //           bookingConfirmationData={bookedServiceData}
  //           where={reduxstate?.serviceData?.venue_detail}
  //         />
  //       </div>
  //     </div>
  //   );
  // }
}

export default ServicePayment;
