import React from "react";

const ErrorAlert = ({ onClose, error, title, isServerError, actionAllowed = true }) => {
  return (
    <div className="error-alert">
      <h1 className="title">
        {isServerError ? "Something went wrong!" : title || ""}
      </h1>
      <p className="description">{error}</p>
      {!actionAllowed ? null : (
        <button className="action" onClick={onClose}>
          Ok
        </button>
      )}
    </div>
  );
};

export default ErrorAlert;
